import '../assets/css/HomePage.css';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Message, Button, Icon, Loader, Segment } from 'semantic-ui-react';
import { useNavigate, useNavigationType } from 'react-router';
import NavBarAuth from '../components/NavBar/NavBarAuth';
import 'react-toastify/dist/ReactToastify.min.css';
import { getDeviceList } from '../services/Devices';
import DropZoneFileUploads from '../components/Tools/FileUploads';
import InterruptedModal from '../components/Modal/InterruptedModal';
import TabBar from '../components/TabBar/TabBar';
import { getTorrentList } from '../services/Torrent';
import { getEnabledProvidersList, getSearch, refreshProvidersList } from '../services/Search';
import {
  getDirectoryList,
  getLocalServerConfig,
  getServerConfig,
  setServerConfig,
} from '../services/Server';
import BackgroundRandomizer from '../components/Tools/BackgroundRandomizer';
import { notifError, notifSuccess } from '../components/Tools/NotificationToasts';
import { AuthContext } from '../contexts/AuthContext';
import InactivityHandler from '../components/Tools/InactivityHandler';
import parseProwlarrContent from '../components/Search/Utils';
import TabBarDiscovery from '../components/TabBar/TabBarDiscovery';
import { firstCharUp, rmBrackets } from '../components/Tools/Utils';
import { Media } from '../components/Tools/AppMedia';
import MediaScheduler from '../components/MediaScheduler/MediaScheduler';

function HomePage() {
  const { logout } = useContext(AuthContext);

  const action = useNavigationType();
  const navigate = useNavigate();
  let localConfig = getLocalServerConfig();

  const [state, setState] = useState({
    activeIndex: 'downloads',
    activeDiscoveryIndex: 'watchlist',
    activeSearchIndex: 0,
    devicesLoading: true,
    devices: [],
    currentDirectory: '/',
    directoryContent: [],
    cloudContentFirstLoad: true,
    cloudLoading: true,
    cloudContent: [],
    configError: false,
    configLoading: true,
    downloadInProgress: 0,
    dragOver: false,
    devicesError: false,
    cloudError: false,
    terms: '',
    searchInput: '',
    searchTabs: [],
    providers: [],
    providersUpdatedAt: Date.now(),
    providersLoading: false,
    providersError: false,
    selectedProvider: 'all',
    messageVisible: true,
    openDiscovery: false,
    discoverySearchInput: '',
    discoverySearchMediaType: 'movie',
    discoverySearchPage: 1,
    discoverySearchTotalPage: 1,
    discoveryWeekMoviePage: 1,
    discoveryWeekMovieTotalPages: 1,
    discoveryWeekTvPage: 1,
    discoveryWeekTvTotalPages: 1,
    trendingWeekMoviePage: 1,
    trendingWeekMovieTotalPages: 1,
    trendingWeekTvPage: 1,
    trendingWeekTvTotalPages: 1,
    openMediaScheduler: false,
  });

  const searchInputRef = useRef();

  const getDirectory = (directory, retry, pushHistory = true) => {
    if (!directory) directory = state.currentDirectory;
    getDirectoryList(directory || state.currentDirectory).then((response) => {
      if (response.status === 200 && response.data.success) {
        setState((prevState) => ({
          ...prevState,
          currentDirectory: directory,
          directoryContent: response.data.raw,
        }));
        if (pushHistory)
          navigate(`${window.location.pathname}?q=${directory}`, {
            state: { directory },
          });
      } else if (!retry) getDirectory('/', true);
    });
  };

  const handleTabChange = (e, data) => {
    setState((prevState) => ({
      ...prevState,
      activeIndex: data.panes ? data.panes[data.activeIndex].pane : data,
    }));
  };

  const handleDiscoveryTabChange = (e, data) => {
    if (data.panes[data.activeIndex].pane !== 'close')
      setState((prevState) => ({
        ...prevState,
        activeDiscoveryIndex: data.panes[data.activeIndex].pane,
      }));
  };

  const handleChangeProvider = (e, { value }) =>
    setState((prevState) => ({ ...prevState, selectedProvider: value }));

  const setSearchInput = (terms) => {
    setState((prevState) => ({ ...prevState, searchInput: terms }));
  };

  const setDiscoverySearchInput = (terms) => {
    setState((prevState) => ({ ...prevState, discoverySearchInput: terms }));
  };

  const setDiscoverySearchPage = (page) => {
    setState((prevState) => ({ ...prevState, discoverySearchPage: page }));
  };

  const setDiscoverySearchTotalPage = (page) => {
    setState((prevState) => ({ ...prevState, discoverySearchTotalPage: page }));
  };

  const setDiscoverySearchMediaType = (media) => {
    setState((prevState) => ({
      ...prevState,
      discoverySearchMediaType: media,
    }));
  };

  const focusSearchInput = () => {
    if (searchInputRef.current !== null) {
      searchInputRef.current.focus();
      searchInputRef.current.inputRef.current.className = 'prompt blink-input';
      setTimeout(() => {
        if (searchInputRef.current !== null)
          searchInputRef.current.inputRef.current.className = 'prompt';
      }, 1200);
    }
    if (state.activeIndex !== 'search') handleTabChange('', 'search');
  };

  const setOpenDiscovery = (open) =>
    setState((prevState) => ({ ...prevState, openDiscovery: open }));

  const setDiscoveryPage = (mode, type, timeWindow, page, totalPages) => {
    setState((prevState) => ({
      ...prevState,
      [`${mode + firstCharUp(timeWindow) + firstCharUp(type)}Page`]: page,
      [`${mode + firstCharUp(timeWindow) + firstCharUp(type)}TotalPages`]: totalPages,
    }));
  };

  const setOpenMediaScheduler = (open) =>
    setState((prevState) => ({ ...prevState, openMediaScheduler: open }));

  const getProvidersList = () => {
    getEnabledProvidersList()
      .then((response) => {
        if (response.status === 200 && response.data.success)
          setState((prevState) => ({
            ...prevState,
            providers: response.data.providers,
            providersUpdatedAt: response.data.updatedAt,
            providersError: false,
          }));
        else setState((prevState) => ({ ...prevState, providers: [], providersError: true }));
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, providers: [], providersError: true }));
      });
  };

  const refreshProviders = () => {
    setState((prevState) => ({ ...prevState, providersLoading: true }));
    refreshProvidersList()
      .then((response) => {
        setState((prevState) => ({ ...prevState, providersLoading: false }));
        if (response.status === 200 && response.data.success) {
          notifSuccess('Liste des fournisseurs mise à jour');
          getProvidersList();
        } else notifError('Échec de mise à jour des fournisseurs');
      })
      .catch((e) => {
        setState((prevState) => ({ ...prevState, providersLoading: false }));
        notifError(`Échec de mise à jour des fournisseurs: ${e.message}`);
      });
  };

  const getDevices = useCallback(() => {
    if (localConfig.devicesEnabled)
      getDeviceList(false)
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            setState((prevState) => ({
              ...prevState,
              devices: response.data.result,
              devicesLoading: false,
              devicesError: false,
            }));
          } else {
            console.log('Could not get devices at the moment');
            console.log(response.status);
            setState((prevState) => ({
              ...prevState,
              devicesLoading: false,
              devicesError: true,
            }));
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) logout();
          else {
            console.log(`Could not get devices: ${error.message}`);
            setState((prevState) => ({
              ...prevState,
              devicesLoading: false,
              devicesError: true,
            }));
          }
        });
    else setState((prevState) => ({ ...prevState, devicesLoading: false }));
  }, [logout]);

  const getConfig = useCallback(() => {
    getServerConfig()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setServerConfig(response.data);
          localConfig = getLocalServerConfig();
          getDevices();
          setState((prevState) => ({ ...prevState, configError: false, configLoading: false }));
        } else setState((prevState) => ({ ...prevState, configError: true, configLoading: false }));
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) logout();
        else setState((prevState) => ({ ...prevState, configError: true, configLoading: false }));
      });
  }, [getDevices, logout]);

  const handleDownloadInProgress = useCallback(
    (count) => {
      if (
        state.downloadInProgress > count &&
        state.downloadInProgress !== count &&
        state.activeIndex === 'downloads'
      )
        getDirectory();
      setState((prevState) => ({ ...prevState, downloadInProgress: count }));
    },
    [state.activeIndex, state.downloadInProgress]
  );

  const getCloudContent = useCallback(() => {
    if (localConfig.torrentEnabled === 'disabled') return;
    getTorrentList()
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          if (response.data.result.has_changed || state.cloudContentFirstLoad) {
            response.data.result.torrents.sort((a, b) => {
              if (a.progress < b.progress) return -1;
              if (a.progress > b.progress) return 1;
              if (firstCharUp(rmBrackets(a.name)) < firstCharUp(rmBrackets(b.name))) return -1;
              if (firstCharUp(rmBrackets(a.name)) > firstCharUp(rmBrackets(b.name))) return 1;
              return 0;
            });
            setState((prevState) => ({
              ...prevState,
              cloudContent: response.data.result.torrents,
              cloudContentFirstLoad: false,
              cloudLoading: false,
              cloudError: false,
            }));
            handleDownloadInProgress(response.data.result.downloading);
          }
        } else
          setState((prevState) => ({
            ...prevState,
            cloudContent: [],
            cloudLoading: false,
            cloudError: true,
          }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          cloudContent: [],
          cloudLoading: false,
          cloudError: true,
        }));
      });
  }, [handleDownloadInProgress]);

  const updateSearchState = (searchId, terms, searchObject) => {
    const searchArray = state.searchTabs;
    const objIndex = searchArray.findIndex((i) => i.id === searchId);
    if (objIndex > -1) searchArray[objIndex] = searchObject;
    else searchArray.push(searchObject);
    setState((prevState) => ({
      ...prevState,
      terms,
      searchTabs: searchArray,
      activeSearchIndex: searchArray.length - 1,
    }));
  };

  const deleteSearchTab = (searchId) => {
    const searchArray = state.searchTabs;
    const objIndex = searchArray.findIndex((i) => i.id === searchId);
    if (objIndex > -1) searchArray.splice(objIndex, 1);
    setState((prevState) => ({
      ...prevState,
      searchTabs: searchArray,
      activeSearchIndex: searchArray.length > 0 ? searchArray.length - 1 : 0,
    }));
  };

  const handleSearchTabChange = (e, { activeIndex }) =>
    setState((prevState) => ({ ...prevState, activeSearchIndex: activeIndex }));

  const handleSearch = (terms) => {
    if (terms.length < 1 || state.searchLoading) return;
    if (state.searchTabs.length > 6) deleteSearchTab(state.searchTabs[0].id);
    updateSearchState(`${terms}-${state.selectedProvider}`, terms, {
      id: `${terms}-${state.selectedProvider}`,
      terms,
      provider: state.selectedProvider,
      data: [],
      loading: true,
      error: false,
    });
    getSearch(terms, state.selectedProvider)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          const data = parseProwlarrContent(response);
          notifSuccess(`Recherche terminée: ${data.length} résultat${data.length > 1 ? 's' : ''}`);
          updateSearchState(`${terms}-${state.selectedProvider}`, terms, {
            id: `${terms}-${state.selectedProvider}`,
            terms,
            provider: state.selectedProvider,
            data,
            loading: false,
            error: false,
          });
        } else {
          notifError('Échec de la recherche');
          updateSearchState(`${terms}-${state.selectedProvider}`, terms, {
            id: `${terms}-${state.selectedProvider}`,
            terms,
            provider: state.selectedProvider,
            data: [],
            loading: false,
            error: true,
          });
        }
      })
      .catch((e) => {
        updateSearchState(`${terms}-${state.selectedProvider}`, terms, {
          id: `${terms}-${state.selectedProvider}`,
          terms,
          provider: state.selectedProvider,
          data: [],
          loading: false,
          error: true,
        });
        notifError(`Échec de la recherche: ${e}`);
      });
  };

  const handleDragOver = (show) => {
    if (show && !state.dragOver) setState((prevState) => ({ ...prevState, dragOver: true }));
    if (!show && state.dragOver) setState((prevState) => ({ ...prevState, dragOver: false }));
  };

  const handleHideMessage = () =>
    setState((prevState) => ({ ...prevState, messageVisible: false }));

  useEffect(() => {
    getConfig();
    document.title = localConfig.siteName || 'Ergo';
    if (action.toString() === 'PUSH') getDirectory();
    getProvidersList();
  }, []);

  useEffect(() => {
    getCloudContent();
    const intervalTorrent = setInterval(() => getCloudContent(), 2000);
    return () => {
      clearInterval(intervalTorrent);
    };
  }, [getCloudContent]);

  return (
    <InactivityHandler>
      <BackgroundRandomizer onDragEnter={() => handleDragOver(true)}>
        <InterruptedModal />
        <DropZoneFileUploads
          dragOver={state.dragOver}
          dragOverCallback={handleDragOver}
          reloadDirectoryCallback={getDirectory}
          currentDirectory={state.currentDirectory}
        />
        {!state.configLoading ? (
          <>
            {!state.configError && (
              <div className="row header">
                <NavBarAuth
                  ref={searchInputRef}
                  dragOver={state.dragOver}
                  uploadCallback={() => handleDragOver(!state.dragOver)}
                  downloadInProgress={state.downloadInProgress}
                  getDirectoryCallback={getDirectory}
                  handleTabChange={handleTabChange}
                  searchInput={state.searchInput}
                  setSearchInput={setSearchInput}
                  searchCallback={handleSearch}
                  selectedProvider={state.selectedProvider}
                  getProvidersList={getProvidersList}
                  refreshProviders={refreshProviders}
                  providersLoading={state.providersLoading}
                  handleChangeProvider={handleChangeProvider}
                  providers={state.providers}
                  providersUpdatedAt={state.providersUpdatedAt}
                  setOpenDiscovery={setOpenDiscovery}
                />
              </div>
            )}
            <ToastContainer />
            {state.messageVisible && (
              <Segment size="tiny" attached="top">
                <Message
                  icon={
                    <span
                      onClick={() => {
                        handleTabChange('', 'sorties');
                        setOpenMediaScheduler(true);
                      }}
                    >
                      <Media greaterThanOrEqual="lg">
                        <Icon size="big" name="cloud download" color="purple" />
                      </Media>
                      <Media lessThan="lg">
                        <Icon name="cloud download" color="purple" size="big" />
                      </Media>
                    </span>
                  }
                  onDismiss={() => handleHideMessage()}
                  header="Téléchargement automatique de Séries"
                  className="clickable"
                  content={
                    <span
                      onClick={() => {
                        handleTabChange('', 'sorties');
                        setOpenMediaScheduler(true);
                      }}
                    >
                      Programmez {localConfig.siteName} pour que votre série se télécharge
                      automatiquement à chaque nouvel épisode disponible ! Cliquez pour Ajouter une
                      série au calendrier
                    </span>
                  }
                />
                {/* <Message */}
                {/*  icon={ */}
                {/*    <Link to="/account"> */}
                {/*      <Media greaterThanOrEqual="lg"> */}
                {/*        <Icon size="big" name="mail" color="teal" /> */}
                {/*        <Icon size="big" name="mobile" color="teal" /> */}
                {/*      </Media> */}
                {/*      <Media lessThan="lg"> */}
                {/*        <Icon name="mobile alternate" size="big" /> */}
                {/*      </Media> */}
                {/*    </Link> */}
                {/*  } */}
                {/*  onDismiss={() => handleHideMessage()} */}
                {/*  header="Notifications Mail & mobile sur iOS / Android" */}
                {/*  content={ */}
                {/*    <Link to="/account"> */}
                {/*      Recevez les notifications par mail ou bien sur votre smartphone grâce à */}
                {/*      l&apos;application <b>Ntfy</b>! Rendez-vous dans <b>Mon Compte</b> pour */}
                {/*      configurer vos notifications */}
                {/*    </Link> */}
                {/*  } */}
                {/* /> */}
              </Segment>
            )}
            {!state.configError ? (
              state.devicesLoading ? (
                <Loader active />
              ) : state.devicesError ? (
                <Segment color="orange" textAlign="center">
                  <Icon name="exclamation triangle" size="big" color="orange" />
                  <br />
                  <br />
                  Une erreur est survenue lors du chargement des appareils
                  <br />
                  <br />
                  <Button color="orange" icon onClick={getDevices}>
                    Réessayer <Icon name="sync alternate" />
                  </Button>
                </Segment>
              ) : (
                <>
                  <TabBar
                    devices={state.devices}
                    activeIndex={state.activeIndex}
                    handleTabChange={handleTabChange}
                    handleSearchTabChange={handleSearchTabChange}
                    cloudContent={state.cloudContent}
                    cloudError={state.cloudError}
                    cloudLoading={state.cloudLoading}
                    activeSearchIndex={state.activeSearchIndex}
                    searchTabs={state.searchTabs}
                    deleteSearchTab={deleteSearchTab}
                    setOpenDiscovery={setOpenDiscovery}
                    setOpenMediaScheduler={setOpenMediaScheduler}
                    setSearchInput={setSearchInput}
                    focusSearchInput={focusSearchInput}
                    currentDirectory={state.currentDirectory}
                    directoryContent={state.directoryContent}
                    getDirectory={getDirectory}
                  />
                  <TabBarDiscovery
                    openDiscovery={state.openDiscovery}
                    handleTabChange={handleDiscoveryTabChange}
                    currentIndex={state.activeDiscoveryIndex}
                    setSearchInput={setSearchInput}
                    setOpenDiscovery={setOpenDiscovery}
                    focusSearchInput={focusSearchInput}
                    setDiscoveryPage={setDiscoveryPage}
                    setDiscoverySearchInput={setDiscoverySearchInput}
                    setDiscoverySearchMediaType={setDiscoverySearchMediaType}
                    setDiscoverySearchPage={setDiscoverySearchPage}
                    setDiscoverySearchTotalPage={setDiscoverySearchTotalPage}
                    discoverySearchInput={state.discoverySearchInput}
                    discoverySearchMediaType={state.discoverySearchMediaType}
                    discoverySearchPage={state.discoverySearchPage}
                    discoverySearchTotalPage={state.discoverySearchTotalPage}
                    discoveryWeekMoviePage={state.discoveryWeekMoviePage}
                    discoveryWeekMovieTotalPages={state.discoveryWeekMovieTotalPages}
                    discoveryWeekTvPage={state.discoveryWeekTvPage}
                    discoveryWeekTvTotalPages={state.discoveryWeekTvTotalPages}
                    trendingWeekMoviePage={state.trendingWeekMoviePage}
                    trendingWeekMovieTotalPages={state.trendingWeekMovieTotalPages}
                    trendingWeekTvPage={state.trendingWeekTvPage}
                    trendingWeekTvTotalPages={state.trendingWeekTvTotalPages}
                  />
                  <MediaScheduler
                    openMediaScheduler={state.openMediaScheduler}
                    setOpenMediaScheduler={setOpenMediaScheduler}
                  />
                </>
              )
            ) : (
              <Segment color="orange" textAlign="center">
                <Icon name="exclamation triangle" size="big" color="orange" />
                <br />
                <br />
                Une erreur est survenue lors de la récupération de la configuration du serveur
                <br />
                <br />
                <Button color="orange" icon onClick={getConfig}>
                  Réessayer <Icon name="sync alternate" />
                </Button>
              </Segment>
            )}
          </>
        ) : (
          <Loader active />
        )}
      </BackgroundRandomizer>
    </InactivityHandler>
  );
}

export default HomePage;
