import React from 'react';
import { Icon, Label, Table } from 'semantic-ui-react';
import { Tooltip } from 'react-tooltip';
import { Media } from '../Tools/AppMedia';
import { getLocalServerConfig } from '../../services/Server';

function DirectoryTableHeader({
  age,
  count,
  checkedItems,
  setOpenDelete,
  setOpenZip,
  setOpenMkdir,
}) {
  const ageLabel = (() => {
    switch (age) {
      case 'new':
        return (
          <Label horizontal color="red">
            Nouveau ({count})
          </Label>
        );
      case 'recent':
        return (
          <Label horizontal color="orange">
            Récent ({count})
          </Label>
        );
      case 'old':
        return (
          <Label horizontal color="black">
            Corbeille ({count})
          </Label>
        );
      case 'error':
        return (
          <Label horizontal color="purple">
            Fichiers illisibles / Verrouillés ({count})
          </Label>
        );
      default:
        return <Label horizontal>Répertoire Vide</Label>;
    }
  })();

  return (
    <>
      <Media greaterThanOrEqual="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row className="tr-header">
              <Table.Cell className="td-center">
                {!checkedItems && (
                  <>
                    <Icon
                      id="mkdir-row-header"
                      className="clickable"
                      name="plus square outline"
                      color="green"
                      onClick={() => setOpenMkdir(true)}
                    />
                    <Tooltip
                      anchorId="mkdir-row-header"
                      effect="solid"
                      place="right"
                      content="Nouveau dossier"
                    />
                  </>
                )}
                {getLocalServerConfig().zipEnabled && (
                  <>
                    <Icon
                      id="zip-row-header"
                      className={checkedItems ? 'clickable blink' : ''}
                      name="file archive outline"
                      color={checkedItems ? 'blue' : 'black'}
                      onClick={checkedItems ? () => setOpenZip(true) : () => {}}
                    />
                    <Tooltip
                      anchorId="zip-row-header"
                      effect="solid"
                      place="right"
                      content={
                        checkedItems ? 'Zipper plusieurs éléments' : 'Cocher des éléments à zipper'
                      }
                    />{' '}
                  </>
                )}
                <Icon
                  id="delete-row-header"
                  className={checkedItems ? 'clickable blink' : ''}
                  name="trash alternate outline"
                  color={checkedItems ? 'red' : 'black'}
                  onClick={checkedItems ? () => setOpenDelete(true) : () => {}}
                />
                <Tooltip
                  anchorId="delete-row-header"
                  effect="solid"
                  place="right"
                  content={
                    checkedItems
                      ? 'Supprimer plusieurs éléments'
                      : 'Cocher des éléments à supprimer'
                  }
                />
              </Table.Cell>
              <Table.Cell className="td-center">
                <Icon name="info" />
              </Table.Cell>
              <Table.Cell className="td-center">
                <Icon name="file archive outline" />
              </Table.Cell>
              <Table.Cell className="td-left">{ageLabel}</Table.Cell>
              <Table.Cell className="td-center" />
              <Table.Cell className="td-center">Type</Table.Cell>
              <Table.Cell className="td-center">Date</Table.Cell>
              <Table.Cell className="td-right">Taille</Table.Cell>
              <Table.Cell className="td-center">
                <Icon name="trash alternate outline" />
              </Table.Cell>
            </Table.Row>
          ) : null;
        }}
      </Media>
      <Media lessThan="lg">
        {(mediaClassNames, renderChildren) => {
          return renderChildren ? (
            <Table.Row className="tr-header">
              <Table.Cell className="td-left">
                {ageLabel}{' '}
                <Icon
                  id="mkdir-row-header"
                  name="plus square outline"
                  color="green"
                  className="clickable"
                  onClick={() => setOpenMkdir(true)}
                />
                <Tooltip
                  anchorId="mkdir-row-header"
                  effect="solid"
                  place="right"
                  content="Nouveau dossier"
                />
              </Table.Cell>
              <Table.Cell />
              <Table.Cell />
            </Table.Row>
          ) : null;
        }}
      </Media>
    </>
  );
}

export default DirectoryTableHeader;
